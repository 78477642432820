<template>
  <div>
    <!--  Page Header  -->
    <base-page-header title="Activity" />

    <div
      class="page-tabs"
      v-show="isGranted('ROLE_CEO') || isGranted('ROLE_CEO_ASSISTANT') || isGranted('ROLE_AUDIT_TRAIL')"
    >
      <div class="row py-2">
        <div class="col-md-6 my-auto">
          <div class="row">
            <div :class="ui.showSearchField ? 'col-4' : 'col-1'">
              <div class="page-filtration">
                <div
                  class="page-filtration__search"
                  :class="{
                    'page-filtration__search_active': ui.showSearchField,
                  }"
                >
                  <button
                    class="btn page-filtration__button"
                    :class="{
                      'page-filtration__button_disable': ui.showSearchField,
                    }"
                    @click="toggleSearchField"
                  >
                    <img src="@/assets/icons/icon-search.svg" alt="" />
                  </button>
                  <div class="form-group">
                    <label for="searchField">
                      <input
                        v-model="filters.query"
                        aria-label="search"
                        placeholder="Search"
                        type="text"
                        @input="search"
                        @focusout="hideInput"
                        id="searchField"
                        ref="searchField"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 table-filter">
              <multiselect
                id="fieldAgent"
                v-model="filters.agent"
                :allow-empty="false"
                :close-on-select="true"
                :options="agents"
                :searchable="true"
                :show-labels="false"
                class="full-width"
                label="name"
                placeholder="Select Agent"
                style="width: 300px"
                track-by="id"
                @select="search"
              >
              </multiselect>
            </div>
            <div class="col-4 table-filter">
              <multiselect
                id="fieldActivity"
                v-model="filters.type"
                :allow-empty="false"
                :close-on-select="true"
                :options="types"
                :searchable="false"
                :show-labels="false"
                class="full-width"
                label="label"
                placeholder="Select Type"
                style="width: 300px"
                track-by="value"
                @select="search"
              >
              </multiselect>
            </div>
          </div>
        </div>
        <div class="col-md-6 my-auto">
          <Paging
            class="float-end"
            :paging="paging"
            :page="currentPage"
            @pageChanged="pagingUpdated"
            @limitChanged="pagingUpdated"
          ></Paging>
        </div>
      </div>
    </div>

    <page-spinner v-if="ui.loading" />
    <div
      v-else-if="isGranted('ROLE_CEO') || isGranted('ROLE_CEO_ASSISTANT') || isGranted('ROLE_AUDIT_TRAIL')"
      class="page-content p-normal"
    >
      <div
        v-if="activities.length === 0"
        class="modal-alert modal-alert_advice text-center"
      >
        No Activity Found
      </div>
      <div v-else class="row activity-list">
        <div
          v-for="(item, index) in activities"
          :key="index"
          class="col-12 py-1 activity-list-content"
        >
          <div class="activity-list-title my-3">
            {{ item.date }}
          </div>
          <div class="row">
            <lazy-component v-for="activity in item.content" :key="activity.id">
              <div class="col-12">
                <div class="row">
                  <div class="col-1 pr-0">
                    <div class="circle-block">
                      <img
                        :src="agentPhoto(activity.agent.profile)"
                        class="agent-avatar"
                      />
                    </div>
                  </div>
                  <div class="col-11 pb-3 pl-0 pr-0">
                    <div class="row w-100 m-0">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-md-10">
                            <div class="row">
                              <div class="col-md-6">
                                <router-link
                                  :to="{
                                    name: 'users_details',
                                    params: { id: activity.agent.id },
                                  }"
                                  class="agent-name"
                                >
                                  {{ activity.agent.name }}
                                </router-link>
                                <span class="activity-short">
                                  {{ activity.changeSet.message }}
                                </span>
                              </div>
                              <div class="col-md-6">
                                <div
                                  class="
                                    d-flex
                                    align-items-center
                                    justify-content-md-end
                                  "
                                >
                                  <span
                                    :data-date="activity.createdAt"
                                    class="date me-2 d-inline-block"
                                  >
                                    {{
                                      formatDate(activity.createdAt, "hh:mm A")
                                    }}
                                  </span>
                                  &#183;
                                  <span
                                    class="ms-2 d-inline-block activity-page"
                                    >{{ activity.type }}</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="agent-action">
                              <div
                                v-if="activity.description"
                                class="app-updated d-flex align-items-center"
                                v-html="activity.description"
                              ></div>
                              <div
                                v-for="(content, indexKey) in activity.changeSet
                                  .content"
                                :key="indexKey"
                              >
                                <div class="app-updated">
                                  <div class="activity__col">
                                    {{ content.property }}
                                  </div>
                                  <div class="activity__col">
                                    <div class="row">
                                      <div class="col-1">
                                        <img
                                          alt=""
                                          src="@/assets/icons/icon-value-removed.svg"
                                        />
                                      </div>
                                      <div class="col-11">
                                        <span>{{ content.oldValue }}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="activity__col">
                                    <div class="row">
                                      <div class="col-1">
                                        <img
                                          alt=""
                                          src="@/assets/icons/icon-value-removed-icon-value-added.svg"
                                        />
                                      </div>
                                      <div class="col-11">
                                        <span>{{ content.newValue }}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </lazy-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import debounce from "@/utils/debounce";
import BasePageHeader from "../../components/BasePageHeader";
import PageSpinner from "../../components/pageSpinner";
import Paging from "../../components/Paging";

export default {
  name: "Activity",
  components: { PageSpinner, BasePageHeader, Multiselect, Paging },
  data() {
    return {
      ui: {
        loading: false,
        showSearchField: false,
      },
      filters: {
        query: "",
        agent: "",
        type: "",
      },
      agents: [],
      types: [],
      activities: [],
      paging: {
        offset: 0,
        limit: 10,
        total: 0,
      },
      currentPage: 1,
      search: debounce(() => {
        this.paging.offset = 0;
        this.currentPage = 1;
        this.searchActivities();
      }, 350),
    };
  },
  methods: {
    getAdditionalData() {
      this.ui.loading = true;
      this.$http
        .get("/api/v1/activities")
        .then((res) => {
          this.agents = res.data.agents;
          this.types = res.data.types;

          this.types.unshift({
            label: "All Types",
            value: "",
          });
          this.agents.unshift({
            name: "All Agents",
            id: 0,
          });

          this.filters.agent = this.agents[0];
          this.filters.type = this.types[0];

          this.searchActivities();
        })
        .catch((err) => this.alertError(err.response.statusText))
    },
    searchActivities() {
      this.ui.loading = true;
      let formData = new FormData();
      formData.append("agent", this.filters.agent.id);
      formData.append("type", this.filters.type.value);
      formData.append("query", this.filters.query);
      formData.append("offset", this.paging.offset);
      formData.append("limit", this.paging.limit);

      this.$http
        .post(`/api/v1/activities/search`, formData)
        .then((res) => {
          this.activities = res.data.activities;
          this.paging = res.data.paging;
        })
        .catch((err) => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.loading = false;
        });
    },
    pagingUpdated(paging) {
      this.currentPage = paging.currentPage;
      this.paging = paging;
      this.searchActivities();
    },
    toggleSearchField() {
      this.ui.showSearchField = true;
      this.$refs.searchField.focus();
    },
    hideInput() {
      this.$refs.searchField.blur();
      this.ui.showSearchField = false;
    },
  },
  created() {
    this.getAdditionalData();
  },
};
</script>

<style lang="scss" scoped>
.page-tabs {
  height: auto;
}

.agent-avatar {
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #dbdbdb;
  margin: 0 auto;
  z-index: 2;

  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
}

.table-filter {
  justify-content: center;
  display: flex;
  align-items: center;
}

.table-filter::v-deep .multiselect {
  min-height: 36px;
  max-height: 36px;
  min-width: 180px;
  width: fit-content;
  @media screen and (max-width: 568px) {
    max-width: 50%;
    min-width: 100%;
  }

  .multiselect__placeholder {
    padding: 0;
  }

  &.multiselect--active {
    .multiselect__tags {
      transition: all 0.3s;
      border: 1px solid rgba(38, 111, 229, 0.5);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__tags {
    min-height: 36px;
    max-height: 36px;
    padding: 8px 40px 0 8px;
    transition: all 0.3s;

    &:hover {
      border: 1px solid rgba(38, 111, 229, 0.2);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__select {
    min-height: 36px;
    max-height: 36px;

    &:before {
      top: 15%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }

  &__single {
    color: rgba(0, 0, 0, 0.46);
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }

  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all 0.3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }

    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }
}

.border-bottom-light {
  border-bottom: 2px solid #e7e8e8;
}

.activity-list {
  @media (min-width: 1024px) {
    max-width: 85%;
  }

  &-title {
    font-size: 21px;
    font-weight: 600;
    margin-left: 15px;
  }

  .circle-block {
    position: relative;
    height: 100%;
    text-align: center;

    &:before {
      position: absolute;
      left: 50%;
      bottom: 10px;
      height: 90%;
      display: block;
      content: "";
      width: 2px;
      background: #e7e8e8;
      z-index: 1;
      border-radius: 8px;
      transform: translate(-50%);

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .activity-short {
    margin-left: 0.3rem;
    opacity: 0.7;
    font-size: 12px;

    @media (max-width: 568px) {
      font-size: 11px;
    }
  }

  .activity-page {
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  .app-updated {
    border: 1px solid rgba(239, 239, 239, 1);
    border-radius: 4px;
    padding: 10px 12px;
    margin-top: 5px;
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }

    .activity__col {
      width: 33%;

      @media (max-width: 768px) {
        width: 100%;
      }

      span {
        word-wrap: break-word;
      }

      &:first-child,
      &:last-child {
        color: rgba(0, 0, 0, 1);
        font-size: 12px;
        letter-spacing: 0;
        line-height: 17px;
      }
      &:last-child {
        @media (max-width: 768px) {
          margin-top: 10px;
        }
      }
      &:first-child {
        font-size: 14px;
      }

      &:nth-child(2) {
        @media (max-width: 768px) {
          margin-top: 10px;
        }
        span {
          color: rgba(0, 0, 0, 0.6);
          font-size: 12px;
          letter-spacing: 0;
          line-height: 17px;
        }
      }
    }
  }

  .date {
    display: inline-block;
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    width: fit-content;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  .agent-name {
    width: fit-content;
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;

    @media (max-width: 568px) {
      font-size: 12px;
    }
  }
}

.page-filtration {
  &__button {
    position: relative;
    z-index: 2;
    top: -3px;

    &.page-filtration__button_disable {
      cursor: none;
      pointer-events: none;
    }

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &__search {
    position: relative;
    min-width: 20px;
    margin-right: 10px;

    .form-group {
      position: absolute;
      opacity: 0;
      z-index: -1;
      top: 0;
      width: 0;
      transition: all 0.3s;

      input {
        height: 34px;
        border-radius: 16px;
        padding-left: 35px;
      }
    }

    &_active {
      min-width: 230px;
      .form-group {
        z-index: 0;
        min-width: 230px;
        opacity: 1;
        input {
          width: 100%;
        }
      }
    }
  }
}
</style>
